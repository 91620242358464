/*
 * @Date: 2023-09-07 11:28:22
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/common";
const params: AnyObject = getUrlParams(window.location.href);
const getRedirect = () => {
  if (process.env.VUE_APP_START == "index") return "/index";
  else if (process.env.VUE_APP_START == "guide") return "/guidance";
  else if (params.qapp) return "/guidance";
  else return "/index";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/index",
    name: "Index",
    redirect: "/pageMain",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      spaceKeys: ["Home_top", "Home_inter", "Home_banner"]
    },
    children: [
      {
        path: "/pageMain",
        name: "Home",
        component: () => import("@/views/home/PageMain.vue")
      },
      {
        path: "/pageGood",
        name: "PageGood",
        component: () => import("@/views/home/PageGood.vue")
      },
      {
        path: "/pageCoupon",
        name: "PageCoupon",
        component: () => import("@/views/home/PageCoupon.vue")
      },
      {
        path: "/PageTakeOut",
        name: "pageTakeOut",
        component: () => import("@/views/home/PageTakeOut.vue")
      }
    ]
  },
  {
    path: "/details",
    name: "GoodDetails",
    component: () => import("@/views/goodDetails.vue"),
    meta: {
      spaceKeys: ["Details_center"]
    }
  },
  {
    path: "/guidance",
    name: "Guidance",
    component: () => import("@/views/splash.vue"),
    meta: {
      spaceKeys: ["Guide_native", "Guide_banner"]
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 0);
    });
  },
  routes
});

export default router;
