const Inter = [
  { space_src: "baidujs", space_type: "inters", space_id: "u6943561", space_js: "//fyxpskb.yxsktech.com/common/m_hg/resource/pdz/openjs/eg/common/q.js" }
];

const NativeLg = [
  { space_src: "baidujs", space_type: "native", space_id: "u6943562", space_js: "//fyxpskb.yxsktech.com/production/n-ih/production/qe/openjs/a-fhx.js" }
];

const NativeSm1 = [{ space_src: "baidujs", space_type: "native", space_id: "u6943563", space_js: "//fyxpskb.yxsktech.com/source/o_j_i/openjs/rf_bgib.js" }];

const NativeSm2 = [
  { space_src: "baidujs", space_type: "native", space_id: "u6943564", space_js: "//fyxpskb.yxsktech.com/production/pk/resource/jsgc/source/h-j-g.js" }
];

/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: Inter, // 官方插屏
  Home_top: NativeLg, // 首页top信息流
  Home_center: NativeSm2, // 首页contenr信息流
  Home_banner: NativeSm1, // Banner
  Details_center: NativeSm2 // 商品详情信息流
};
