import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import type { StorageLike } from "pinia-plugin-persistedstate";
import SecureLs from "secure-ls";

const secretKey = "8SClvQlN0BFWmkTP";
const ls = new SecureLs({ encodingType: "aes", isCompression: true, encryptionSecret: secretKey });

const store = createPinia();
store.use(piniaPluginPersistedstate);

export const st: StorageLike = {
  setItem(key: string, value: string) {
    ls.set(key, value);
  },
  getItem(key: string): string | null {
    return ls.get(key);
  }
};

export default store;
